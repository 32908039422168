
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"18","viewBox":"0 0 18 18"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"fill":"none","fill-rule":"evenodd","transform":"rotate(-90 9 9)"}},[_c('polygon',{attrs:{"points":"0 0 18 0 18 18 0 18"}}),_c('polygon',{staticClass:"arrow-svg",attrs:{"fill":"#FFF","points":"15.75 8.25 5.122 8.25 7.808 5.558 6.75 4.5 2.25 9 6.75 13.5 7.808 12.443 5.122 9.75 15.75 9.75"}})])])
          )
        }
      }
    