<template>
  <component :is="require(`@/assets/images/icons/${name}.svg?inline`)" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
